import React from "react";
import { get } from 'lodash';
import { Link } from "@StarberryUtils"
import {Row, Col, Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import GenerateLink from "../common/site/generate-link"
import { sitename } from "@constants";
import ReviewsCount from '../Home/Reviews/review-count'
import GoogleReview from "../../images/google-reviews-white.svg"
import "./StaticBanner.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const StaticPage = (props) => {

    const ctas = get(props, 'Buttons', []);
    const img = get(props, 'Banner_Image.url')
    console.log('Page_Modules ctas', ctas)
    const [showModal, setShowModal] = React.useState(false);
    const [modalData, setModalData] = React.useState({});
    const openModal = () => {
      setShowModal(true);
    }
    const hideModal = () => {
      setShowModal(false);
    }
  
    const handleonClick = (e, cta) => {
      e.preventDefault();
      setShowModal(true);
      setModalData(cta)
    }
  
    let gatsbyImg = getImage(props?.Banner_Image?.url_sharp)
  
    let pagename = props?.Pagename;
    let isHome = false;
    if (pagename === 'Home'){
      pagename = 'property service';
      isHome = true;
    }
  
  
    return (
      <section className="home-banner home-banner-links">
        <div className="banner-item">
            <div className="banner-img">
              {/* When using ggfx clitn component, banner image getting time to load, facing some jump issue. Using gatsby image to over come this */}
              <GatsbyImage image={gatsbyImg} alt={img.alternativeText || `${pagename} Banner - ${sitename} Estate Agents`} className="d-block" />
            </div>
  
            <div className="banner-content-alt d-lg-flex justify-content-center">
              <div className="static-services">
                <div>
                 {isHome?
                 <>
                  {props.Heading && (
                    <h2 className="h1">{props.Pre_Title &&
                      <span className={`${isHome ? "normal" : "bold"}`}>
                        {props.Pre_Title}
                      </span>
                    } <span className={`${isHome ? "bold" : "normal"}`}>{props.Heading}</span></h2>
                  )}
                   {props.Sub_Heading && (
                    <h1 className="h2">{props.Sub_Heading}</h1>
                  )}
                  </>
                   :
                  props.Heading && (
                    <h1>{props.Pre_Title &&
                      <span className={`${isHome ? "italic" : "bold"}`}>
                        {props.Pre_Title}
                      </span>
                    } <span className={`${isHome ? "bold" : "normal"}`}>{parse(props.Heading)}</span></h1>
                  )
                }
  
                  {props.Banner_Main_Content &&
                    <>{parse(props.Banner_Main_Content)}</>
                  }
                    <div className="btn-list">
                          {props.Banner_CTA_Link && props.Pagename != "Careers" ?
                          <>
                          <GenerateLink class="btn transparent" link={props.Banner_CTA_Link} label={props.Banner_CTA_Label} >
                          {props.Banner_CTA_Label}
                          </GenerateLink>
                           { props.Pagename ==  "Reviews" && (<a class="btn transparent" href="/contact/">Contact Us</a>) }
                           </>
                          :
                          <a class="btn transparent all-jobs-list">{props.Banner_CTA_Label}</a>                       
                          }
                    </div>
                      <div className="google-reviews">
                        <picture>
                          <img
                          src={GoogleReview}
                          alt="reviews-img01"
                          className="google_review_logo"
                          />
                        </picture>
                        <ReviewsCount />
                    </div>
                </div>
              </div>
            </div> 
        </div>
      </section>
    )
  };

export default StaticPage




// Header component

/*const StaticPage = (props) => {
    const contentClass = 'banner-content banner-content-alt';
    return (
        <React.Fragment>
                <section className="static-banner">
                    {props.Banner_Image &&
                    <div className="banner-img">
                        <Img fluid={props.Banner_Image.url_sharp.childImageSharp.fluid} alt={props.Banner_Image.alternativeText || `${props.Pagename} Banner - ${sitename} Estate Agents`} />
                    </div>
                    }
                    <div className={contentClass}>
                        <Container>
                            <Row>
                                <Col lg={12} xl={12} >
                                    {props.Banner_Title_Heading &&
                                    <span className="sm-info banner-sm-info">
                                        {props.Banner_Title_Heading}
                                    </span>
                                    }

                                    {parse(props.Banner_Main_Content)}

                                    {(props.Pagename !== 'Rent' && props.Pagename !== 'Buy') && (
                                        <>
                                        {props.Banner_CTA_Link &&
                                            <GenerateLink class="btn" link={props.Banner_CTA_Link}>
                                                {props.Banner_CTA_Label}
                                            </GenerateLink>
                                        }
                                        </>
                                    )}
                                    {(props.Pagename === 'Rent' || props.Pagename === 'Buy') && (
                                        <Search {...props} />
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
        </React.Fragment>
    );
};
export default StaticPage;*/
